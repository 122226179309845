.player {
    position: absolute;
    top: 100%;
    font-size: 1rem;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: left 60ms ease, right 60ms ease;
}

.space {
    min-width: 90%;
    max-width: 90%;
}

.App * {
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.space .bullet {
    position: absolute;
    font-size: 0.5rem;
    transform: translateY(-50%);
    /* animation: mymove 200ms forwards ; */
    transition: top 600ms linear;
}

.space .enemy {
    position: absolute;
    top: 1rem;
    font-size: 1rem;
    transition: top 1s linear;
    animation: mymove 1s forwards;
}

.score {
    position: fixed;
    top: 0rem;
    right: 0.5rem;
    font-size: 0.5rem;
}

@media screen and (max-width: 800px) {
    .space .enemy {
        font-size: 0.65rem;
    }

    @keyframes mymove {
        0% {
            transform: translateY(-200%);
        }

        100% {
            transform: translateY(0%);
        }
    }
}