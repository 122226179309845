.cardmakerrules , .cardmakergalery , .cardmaker {
    border-radius: 0.5rem;
    border: 0.1rem solid white;
    padding: 0.2rem;
    margin: 0.2rem;
}

.cardmaker .cont {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: clamp(3rem, 70%, 5rem);
}

.cardmakergalery .cont {
    width: clamp(3rem, 70%, 5rem);
}

.cardmakersubmit {
    text-align: center;
    background: linear-gradient(-3deg, rgb(179, 15, 15), rgb(82, 41, 148));
    border-radius: 0.5rem;
    font-size: 0.5rem;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: clamp(3rem, 70%, 5rem);
}

.cardmakertitle{
    text-align: center;
    font-size: 0.5rem;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: clamp(3rem, 70%, 5rem);
    text-decoration: underline;
    padding-bottom: 0.2rem;
}

.cardmakergalery{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.cardmakergalery .cardmakertitle{
    width: 100%;
}

.cardmakergalery .cont{
    display: flex;
    /* width: clamp(3rem, 30%, 5rem);
    min-width: 150px; */
}

.cardrules{
    font-size: 0.4rem;
    background-color: rgba(240, 248, 255, 0);
    border: none;
    color: aliceblue;
    width: 100%;
}