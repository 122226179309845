.oc-batlefields {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(46, 45, 45);
}

.oc-batlefields * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    border-radius: 7%;
}

.oc-controller {
    position: absolute;
    top: 60%;
    left: 0;
    width: 20%;
    height: 40%;
    background-color: rgba(102, 99, 50, 0);
    z-index: 10;
    border-radius: 50%;
}

.oc-controller * {
    border: 2px solid rgb(180, 178, 178);
}

.oc-controller-font {
    border: none;
    color: aliceblue;
    font-size: medium;
    height: 100%;
    width: 100%;
    text-align: center;
    transform: translateY(25%);
}

.oc-todraw {
    background-color: black;
    z-index: 25;
    width: 60%;
    height: 60%;
    position: absolute;
    top: 35%;
    left: 5%;
    border-radius: 50%;
}

.oc-todeck {
    background-color: black;
    z-index: 25;
    width: 30%;
    height: 30%;
    position: absolute;
    top: 5%;
    left: 17%;
    border-radius: 50%;
}

.oc-tograve {
    background-color: black;
    z-index: 25;
    width: 30%;
    height: 30%;
    position: absolute;
    top: 4%;
    left: 48%;
    border-radius: 50%;
}

.oc-tohand {
    background-color: black;
    z-index: 25;
    width: 30%;
    height: 30%;
    position: absolute;
    top: 25%;
    left: 68%;
    border-radius: 50%;
}

.oc-todiscard{
    background-color: black;
    z-index: 25;
    width: 30%;
    height: 30%;
    position: absolute;
    top: 55%;
    left: 64%;
    border-radius: 50%;
}


.oc-hand {
    position: absolute;
    top: 73%;
    left: 20%;
    width: 63%;
    height: 30%;
    background-color: rgb(88, 47, 83);
    z-index: 10;
}

.oc-gui {
    position: absolute;
    top: 0;
    left: 83%;
    width: 17%;
    height: 100%;
    background-color: rgb(110, 95, 122);
    border-radius: 50% 0 0 50%;
}

.oc-nmi-gui {
    position: absolute;
    top: 7%;
    width: 100%;
    height: 40%;
    background-color: rgb(157, 137, 175);
    z-index: 20;
}

.oc-player-gui {
    position: absolute;
    top: 51%;
    width: 100%;
    height: 40%;
    background-color: rgb(157, 137, 175);
    z-index: 20;
}

.oc-gui-font {
    font-size: 12px;
    text-align: center;
    padding-top: 20%;
    padding-left: 5%;
    font-weight: bold;
}

.oc-p1-battlefield {
    padding: 5px;
    margin: 5px;
    position: absolute;
    top: 40%;
    left: 1%;
    width: 80%;
    height: 55%;
    background-color: rgb(59, 59, 94);
}

.oc-p2-battlefield {
    padding: 5px;
    margin: 5px;
    position: absolute;
    top: 1%;
    left: 1%;
    width: 80%;
    height: 35%;
    background-color: rgb(90, 61, 61);
}

.oc-card-hand {
    background-color: aliceblue;
    width: 10%;
    height: 10%;
}

.oc-card-hand:nth-child(1) {
    background-color: aliceblue;
    width: 12%;
    height: 80%;
    position: absolute;
    top: 8%;
    left: 2%;
}

.oc-card-hand:nth-child(2) {
    background-color: aliceblue;
    width: 12%;
    height: 80%;
    position: absolute;
    top: 8%;
    left: 16%;
}

.oc-card-hand:nth-child(3) {
    background-color: aliceblue;
    width: 12%;
    height: 80%;
    position: absolute;
    top: 8%;
    left: 30%;
}

.oc-card-hand:nth-child(4) {
    background-color: aliceblue;
    width: 12%;
    height: 80%;
    position: absolute;
    top: 8%;
    left: 44%;
}

.oc-card-hand:nth-child(5) {
    background-color: aliceblue;
    width: 12%;
    height: 80%;
    position: absolute;
    top: 8%;
    left: 58%;
}

.oc-card-hand:nth-child(6) {
    background-color: aliceblue;
    width: 12%;
    height: 80%;
    position: absolute;
    top: 8%;
    left: 72%;
}

.oc-card-hand:nth-child(7) {
    background-color: aliceblue;
    width: 12%;
    height: 80%;
    position: absolute;
    top: 8%;
    left: 86%;
}

.oc-card-battlefield{
    background-color: aliceblue;
    height: 45%;
    width: 15%;
    position: absolute;
    top: 3%;
}

.oc-card-battlefield:nth-child(1){
    left: 19%;
}

.oc-card-battlefield:nth-child(2){
    left: 35%;
}

.oc-card-battlefield:nth-child(3){
    left: 51%;
}

.oc-card-battlefield:nth-child(4){
    left: 67%;
}

.oc-card-battlefield:nth-child(5){
    left: 83%;
}

.oc-soul-battlefield:nth-child(1){
    left: 3%;
}

.oc-soul-battlefield:nth-child(2){
    left: 35%;
}

.oc-soul-battlefield:nth-child(3){
    left: 68%;
}

.oc-soul-battlefield{
    background-color: blue;
    border-radius: 50%;
    width: 30%;
    height: 30%;
    position: absolute;
    top: 60%;
    left: initial;
}



.oc-p2-battlefield > .oc-card-battlefield{
    height: 70%;
    top: 15%;
}

.oc-vessel-battlefield-active{
    background-color: rgb(44, 87, 124);
    height: 55%;
    width: 40%;
    position: absolute;
    top: 1%;
    left: 30%;
}

.oc-vessel-battlefield-passive{
    background-color: rgb(44, 87, 124);
    height: 55%;
    width: 40%;
    position: absolute;
    top: 1%;
    left: 30%;
    transform: rotate(90deg);
}

.oc-popup{
    z-index: 45;
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    background-color: rgb(240, 248, 255);
    border: 3px solid black;
}

.oc-card-popup{
    z-index: 50;
    position: absolute;
    top: 20%;
    width: 19%;
    height: 60%;
    background-color: rgb(34, 91, 141);
}

.oc-card-popup:nth-child(1){
    left: 10%;
}

.oc-card-popup:nth-child(2){
    left: 31%;
}

.oc-card-popup:nth-child(3){
    left: 52%;
}

.oc-card-popup:nth-child(4){
    left: 73%;
}

.oc-cardfill{
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
}