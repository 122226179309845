body {
    background-color: aqua;
}

.cont {
    max-width: 100%;
    aspect-ratio: 2.5/3.5;
    background-color: rgba(0, 0, 0, 0);
}

.card {
    width: 100%;
    height: 100%;
    position: relative;
}

.baseplate {
    width: 100%;
    height: 100%;
}

.rune1 {
    width: 16%;
    position: absolute;
    top: 13%;
    left: 2%;
}

.rune2 {
    width: 16%;
    position: absolute;
    top: 24%;
    left: 2%;
}

.rune3 {
    width: 16%;
    position: absolute;
    top: 35%;
    left: 2%;
}

.skill1 {
    width: 14%;
    padding: 2%;
    position: absolute;
    top: 67.5%;
    left: 3.2%;
}

.skill2 {
    width: 14%;
    padding: 2%;
    position: absolute;
    top: 86%;
    left: 3.2%;
}

.bpmodifier {
    width: 14%;
    padding: 2%;
    position: absolute;
    top: 86%;
    left: 3%;
    filter: drop-shadow(0px 0px 1px black);
}

.bp {
    width: 20%;
    height: 17%;
    padding: 5%;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%);
}

.cardname{
    position: absolute;
    width: 100%;
    top: 1%;
    left: 0%;
    /* font-size: 0.7rem; */
    background-color: rgba(0, 0, 0, 0);
    border: none;
    text-align: center;
    color: rgb(223, 222, 222);
    filter: drop-shadow(0 0 3px black);
}

.skilltext1{
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: rgb(223, 222, 222);
    top:65%;
    left: 18%;
    height: 12%;
    width: 70%;
    text-align: left;
    resize: none;
}

.skilltext2{
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: rgb(223, 222, 222);
    top:85%;
    left: 18%;
    height: 13%;
    width: 70%;
    text-align: left;
    resize: none;
}