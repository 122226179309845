body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

html {
  font-size: calc(2.5 * 16px);
}
@media screen and (min-width: 250px) {
  html {
    font-size: calc(2.5*(16px + 16 * ((100vw - 250px) / 750)));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: calc(2.5 * 32px);
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Georgia, 'Times New Roman', Times, serif;
  overflow: hidden;
}

.App {
  background-image: url(./background.png);
  background-size: cover;
  background-blend-mode: multiply;
  background-position: center;
  background-color: blueviolet;
  color: aliceblue;
  min-height: 100vh;
  padding-top: 1rem;
}

.App a:visited,.App a{
  color: aliceblue;
}

::-webkit-scrollbar {
  scroll-behavior: smooth;
  width: 0.1rem;
  height: 0.1rem;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.7);
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(82, 41, 148, 0.8);
}