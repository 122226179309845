h1{
    font-size: 1rem;
}

.todocontent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ticket{
    margin: 5px;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    border: 0.1rem solid aliceblue;
    border-radius: 1rem;
}

.ticket .textbox{
    display: flex;
    background-color: rgba(255, 255, 255, 0.212);
    border-radius: 1rem;
    width: calc(100% - 1rem);
    color: aliceblue;
    padding: 0.3rem;
}

.ticket .icon{
    display: flex;
    font-size: 1rem;
}

.ticket .task{
    display: flex;
    font-size: 0.5rem;
    border-radius: 1rem;
    width: calc(100% - 1rem);
    color: aliceblue;
    padding: 5px;
    /* overflow: hidden; */
    
}
