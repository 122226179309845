.mainpage h1 {
    font-size: 1rem;
    width: 100%;

}

.mainpage p{
    font-size: 0.2rem;
    min-width: 45ch;
    max-width: 65ch;
}

.mainpage{
    text-align: justify;
    padding: 0.5rem;
    padding-top: 0px;
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%);
}