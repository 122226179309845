 .navbar {
    font-size: 0.2rem;
    background:linear-gradient(-3deg,rgb(179, 15, 15),rgb(82, 41, 148));
    position: fixed;
    top: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.08rem;
    min-width: clamp(5rem, 50%, 8rem);
    min-height: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    animation: shiftup 0.4s ease-in forwards;
    box-shadow: 2px 2px 4px 1px rgb(18, 18, 18);
    transition: top 0.3s;
  }

  .navbar-base {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    min-width: 100%;
    min-height: 1rem;
  }

  .navbar:hover {
    top: 0.1rem;
  }

  .navbar-settings{
    display: none;
  }

  .navbar-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .nav-link {
    text-decoration: none;
  }

  .navbar-links .icon {
    font-size: 0.75rem;
  }

  
  
  @keyframes shiftup {
    0% {
      margin-top: 2.5rem;
    }
  
    100% {
      margin-top: 0;
    }
  }

  .navbar-links:hover{
    background-color:rgba(52, 3, 56,0.3);
    border-radius: 10px;
  }