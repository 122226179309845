.projectlist .icon{
    font-size: 5rem;
    border: 0.1rem white solid;
    border-radius: 1rem;
    padding: 0.5rem;
    margin: 0.5rem;
}

.projectlist{
    display: flex;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

html{
    overflow: scroll;
}

h1{
    text-align: center;
}